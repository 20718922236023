import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "60px 60px 0px 60px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        direction="column"
        alignItems="center"
        spacing={4}
        style={{ paddingBottom: "80px", paddingTop: "40px" }}
      >
        <Grid item xs={10} sm={10} md={10} lg={5}>
          <h2 className={classes.title}>Who We Are</h2>
          <p className={classes.paragraph2}>
            Founded in 2005, GoPro plumbing has provided the highest level of
            service and dedication to its clients in the GTA. Specializing in
            home renovations, commercial and new builds, we are ready to take on
            the most demanding projects.
          </p>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={5}>
          <h2 className={classes.title}>Mission</h2>
          <p className={classes.paragraph2}>
            At GoPro Plumbing, it is always our mission to provide you with the
            highest quality of workmanship, value, and customer service. We are
            wholly dedicated to the fulfillment of our clients' expectations and
            the implementation of our core values
          </p>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={5}>
          <h2 className={classes.title}>Vision</h2>
          <p className={classes.paragraph2}>
            GoPro Plumbing will be the preferred provider of plumbing services
            for the customers and markets we serve. We will set the industry
            standard for quality, safety and on-time performance.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
