import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const getImages = graphql`
  {
    file(relativePath: { eq: "about-us-plumbers.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();
  const data = useStaticQuery(getImages);
  return (
    <div className={classNames(classes.section2, classes.sectionDark)}>
      <div>
        <Grid container spacing={1} align="center">
          <Grid item lg={12} xs={12} sm={10} md={12}>
            <h2 className={classes.title} style={{ textAlign: "center" }}>
              CORE VALUES
            </h2>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            sm={10}
            md={7}
            style={{ paddingTop: "80px" }}
          >
            <Grid item lg={9} xs={10} sm={12} md={12}>
              <div>
                <Img
                  alt="leak-detection-"
                  className={classes.imageDesktop}
                  fluid={data.file.childImageSharp.fluid}
                  fadeIn={false}
                  loading="eager"
                />
              </div>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12} sm={10} md={7}>
            <Grid
              container
              justify="flex-start"
              direction="column"
              alignItem="right"
              alignContent="flex-start"
            >
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title4}>Integrity</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  Professionalism, ethics and honesty in all our actions and
                  relations.
                </p>
              </Grid>

              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title5}>SUPPORT</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  Understanding our clients’ needs and supporting them to meet
                  their end goal. Delivering excellent services and after sales
                  services to maintain continuous support.
                </p>
              </Grid>
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title5}>RESPECT</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  Dignity for all and awareness of how our actions and
                  behaviours impact others.
                </p>
              </Grid>
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title5}>SAFETY</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  Above all else, safety comes first. At the end of the day we
                  must all go home to our loved ones.
                </p>
              </Grid>
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title5}>LEADERSHIP</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  Elevate yourself and elevate others with you.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
